
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { StoryblokRichTextInterface } from '~/types/storyblok';
import { renderRichText } from '~/utils/storyblok';

// https://www.storyblok.com/faq/how-to-render-richtext-nuxt
export default defineComponent({
    name: 'RichTextComponent',
    props: {
        content: {
            type: Object as PropType<StoryblokRichTextInterface>,
            required: true,
        },
    },
    computed: {
        renderedRichText() {
            return renderRichText(this.content);
        },
    },
});
