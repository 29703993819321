
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ArticleRichContentBlokInterface } from '~/types/articlePage';

export default defineComponent({
    name: 'ArticleRichContent',
    props: {
        blok: {
            type: Object as PropType<ArticleRichContentBlokInterface>,
            required: true,
        },
    },
});
