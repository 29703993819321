import { render, staticRenderFns } from "./ArticleRichContent.vue?vue&type=template&id=08cd036f&scoped=true"
import script from "./ArticleRichContent.vue?vue&type=script&lang=ts"
export * from "./ArticleRichContent.vue?vue&type=script&lang=ts"
import style0 from "./ArticleRichContent.vue?vue&type=style&index=0&id=08cd036f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08cd036f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RichTextComponent: require('/home/runner/work/es-cms-articles/es-cms-articles/components/RichTextComponent.vue').default})
